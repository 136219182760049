export * from './token_list'

export const CRAY_TRANSFER_ADDRESS = { MAINNET: '0x9077f79E3EC998Dc10c55F9C1998D66c2636e7Aa', TESTNET: '0x3CFCBd9BF76125AfaFF895711384110EaFB1Fb7c' }
export const REFETCH_INTERVAL = 1000 * 15
export enum AUTHENTICATION_STATE {
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}
export const ERROR_MAP = {
  'Error: insufficient funds': 'Error: insufficient Gas fee',
  'transfer amount exceeds balance': 'Error: insufficient balance',
  '429': 'App has exceeded its compute units per second capacity',
  'replacement fee too low': 'Replacement fee too low',
  'Low Gas Fee': 'insufficient Gas fee',
  "You don't have sufficient gas": "You don't have sufficient gas to complete this payment.",
  'Order Not Found': "This Payment request does't exists",
}
export const NATIVE_TOKEN_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
export enum CHAIN_ID {
  POLYGON = 137,
  MAINNET = 1,
  XDAI = 100,
  ARBITRUM = 42161,
  FANTOM = 250,
  OPTIMISM = 10,
  AVAX = 43114,
  BSC = 56,
  AURORA = 1313161554,
  BASE = 8453,
  AVAX_CHAIN_ID = 43114,
  POLYGON_ZKEVM_TESTNET = 2442,
  ETH_SEPOLIA = 11155111,
  POLYGON_ZKEVM_CARDONA = 2442,
  OP_SEPOLIA_CHAIN_ID = 11155420,
  ARB_SEPOLIA_CHAIN_ID = 421614,
  BASE_SEPOLIA_CHAIN_ID = 84532,
  POLYGON_AMOY_CHAIN_ID = 80002,
  ZKSYNC_ERA_CHAIN_ID = 324,
  POLYGON_ZKEVM_CHAIN_ID = 1101,
  LINEA_CHAIN_ID = 59144,
  AVAX_FUJI_CHAIN_ID = 43113,
}
export const USDC_TOKENS = {
  [CHAIN_ID.POLYGON]: {
    '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359': {
      name: 'USD Coin',
      image: 'https://polygonscan.com/token/images/usdc_32.png',
    },
    '0x2791bca1f2de4661ed88a30c99a7a9449aa84174': {
      name: 'USD Coin (PoS)',
      image: 'https://polygonscan.com/token/images/usdc_32.png',
    },
  },

  [CHAIN_ID.ETH_SEPOLIA]: ['0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238'],
}

export const NETWORKS: { [key: string]: any } = {}

export const DESTINATION_ACTION_ADDRESS = '0xeE1961305210EA0F608cD3B0af0210d5a442Fbd3'

export const SIGN_TYPE = {
  Permit: [
    { name: 'owner', type: 'address' },
    { name: 'spender', type: 'address' },
    { name: 'value', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'deadline', type: 'uint256' },
  ],
}
export const IOS_TRANSITION = `
.slideInRight-enter {
  transform: translate(100%, 0);
  z-index: 1;
  background-color: white;
}

.slideInRight-enter.slideInRight-enter-active {
  transform: translate(0, 0);
  transition: transform 300ms ease;
}
  
.fadeIn-enter {
  opacity:0;
  z-index: 1;
  background-color: white;
}

.fadeIn-enter.fadeIn-enter-active {
  opacity:1;
  transition: opacity 300ms ease;
}
.slideUp-enter {
  transform: translate(0, 100%);
  z-index: 1;
  background-color: white;
}

.slideUp-enter.slideUp-enter-active {
  transform: translate(0, 0);
  transition: transform 300ms ease;
}
`
export const WEB_TRANSITION =
  IOS_TRANSITION +
  ` 
.slideInRight-exit {
  position: absolute;
  top: 0;
  z-index: -1;
}
.slideInRight-exit.slideInRight-out-exit-active {
  transition: transform 300ms ease;
}
.slideInLeft-enter {
  transform: translate(-100%, 0);
  z-index: 1;
  background-color: white;
}

.slideInLeft-enter.slideInLeft-enter-active {
  transform: translate(0, 0);
  transition: transform 300ms ease;
}
.slideInLeft-exit {
  position: absolute;
  top: 0;
  z-index: -1;
}
.slideInLeft-exit.slideInLeft-exit-active {
  transition: transform 300ms ease;
} `
export const MAX_ALLOWANCE_VALUE = 10 ** 15
export const MIN_ALLOWANCE_VALUE = 10 ** 10
