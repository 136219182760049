import { Button } from '@/cn/ui/button'
import { popup } from '@/popup_handler'

const DemoApp = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-6">
      <div className="flex items-center gap-4">
        <div className="bg-green-500 rounded-full w-2 h-2 animate-pulse "></div>
        <span className="text-xl font-bold text-green-500">Minting is live!</span>
      </div>
      <h1 className="text-6xl  text-black font-bold mb-8 leading-tide">Demo DApp</h1>
      <img src="/cray-nft.webp" className="w-[300px] h-[300px] rounded-md shadow-lg" />
      <Button onClick={() => popup.open('demo_mint')}>Mint Now 0.00065 ETH</Button>
    </div>
  )
}
export default DemoApp
