import { PaymentService } from '@/api'
import { WalletService } from '@/api/wallet'
import { rpcProvider } from '@/config/rpcProvider'
import { CHAIN_ID, tokenSymbolMap } from '@/constants'
import { IWallet, OrderType } from '@/interface'
import { Wallet, ethers, parseUnits } from 'ethers'
import { combine } from 'shamir-secret-sharing'
import { arrayBuffertoString, hexStringToArrayBuffer, queryClient, sha256 } from '.'
import { CRYPTO } from './crypto'
import { NativeBridge } from './native_bridge'
interface IDomainData {
  domainData: { chainId: CHAIN_ID; verifyingContract: string; version: string; name: string; nonce: number }
  types: {}
  values: { deadline: string; value: number }
}

const CURRENT_SUPPORTED_VERSION = 1
export class WALLET {
  static Get = (walletId: string) => {
    const wallets = WALLET.GetAll()
    return wallets[walletId]
  }
  static GetUserWallet = () => {
    const { _id: userId } = queryClient.getQueryData(['user']) as any
    let walltes = Object.values(WALLET.GetAll()) as IWallet[]
    return walltes.find((wallet: IWallet) => wallet.userId === userId && wallet.version === CURRENT_SUPPORTED_VERSION)
  }
  static GetAll = () => JSON.parse(window.localStorage.getItem('wallets') || '{}') as any

  static _reset_local_wallet = () => {
    window.localStorage.setItem('wallets', '{}')
  }
  static _push = ({ _id, mnemonicCipher, address, version }: { _id: string; mnemonicCipher: string; address: string; version: number }) => {
    const { _id: userId } = queryClient.getQueryData(['user']) as any
    if (!mnemonicCipher || !address || !_id || !userId) {
      throw new Error('Invalid Wallet Data')
    }
    const wallets: any = WALLET.GetAll()
    wallets[_id] = { mnemonicCipher, address, _id, userId, version }
    try {
      window.localStorage.setItem('wallets', JSON.stringify(wallets))
    } catch (error) {
      console.log(error)
      WALLET._reset_local_wallet()
      throw error
    }
  }

  static Create = async () => {
    try {
      let address = '',
        encryptionKey = '',
        mnemonicCipher = ''
      const { _id: userId } = queryClient.getQueryData(['user']) as any

      if (NativeBridge.isBridge()) {
        ;({ address, encryptionKey } = await NativeBridge.Send('CREATE_WALLET'))
        mnemonicCipher = 'NATIVE_WALLET'
      } else {
        const mnemonic = ethers.Wallet.createRandom().mnemonic?.phrase || ''
        const mnemonicInstance = ethers.Mnemonic.fromPhrase(mnemonic)
        const firstAccount = ethers.HDNodeWallet.fromMnemonic(mnemonicInstance, `m/44'/60'/0'/0/0`)

        encryptionKey = CRYPTO.GeneratePassphrase()
        mnemonicCipher = CRYPTO.EncryptData(mnemonic, encryptionKey)
        address = await firstAccount.getAddress()
      }
      let {
        result: { _id, isPrimary, version },
      } = await WalletService.Create({
        encryptionKey,
        address,
      })
      WALLET._push({ _id, mnemonicCipher, address, version })
      return { _id, mnemonicCipher, address, isPrimary, userId, version }
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  static _getWalletEncryptionKey = async () => {
    const {
      result: { encryptionKey },
    } = await WalletService.Passphrase()
    return encryptionKey
  }

  static _getWalletMnemonicPhrase = async () => {
    const wallet = WALLET.GetUserWallet() as IWallet
    const encryptionKey = await this._getWalletEncryptionKey()
    let mnemonic = CRYPTO.DecryptData(wallet.mnemonicCipher, encryptionKey)
    return { mnemonic, encryptionKey, address: wallet.address }
  }

  static _getWalletProvider = async (chainId: CHAIN_ID[] = [CHAIN_ID.MAINNET]) => {
    const { mnemonic } = await WALLET._getWalletMnemonicPhrase()
    const mnemonicInstance = ethers.Mnemonic.fromPhrase(mnemonic)
    const firstAccount = ethers.HDNodeWallet.fromMnemonic(mnemonicInstance, `m/44'/60'/0'/0/0`)
    const { privateKey } = firstAccount
    return chainId?.map((chainId) => {
      const p = chainId ? rpcProvider[chainId] : undefined
      return new Wallet(privateKey, p)
    })
  }

  static _handleGasTx = async (wallet: IWallet, data: any) => {
    const p = data.map(({ chain }: { chain: CHAIN_ID }) => rpcProvider[chain])
    let feeData = await Promise.all(p.map((p: any) => p.getFeeData()))
    const gasPrice = feeData.map((_: any) => Number(_.gasPrice) * 80_000)
    const nativeBalance = await Promise.all(p.map((p: any) => p.getBalance(wallet.address)))
    const isLowGas = gasPrice.some((gas: any, i: number) => nativeBalance[i] < gas)
    if (isLowGas) {
      throw new Error("You don't have sufficient gas to complete this payment.")
    }
    if (NativeBridge.isBridge()) {
      return NativeBridge.Send('SEND_TX', { data })
    } else {
      const account = await WALLET._getWalletProvider(data.map((_: any) => _.chain))
      delete data[0].chain
      delete data[0].nonce
      delete data[0].gasLimit
      delete data[0].gasPrice
      return Promise.all(
        data.map(async (data: any, i: number) =>
          account?.[i].sendTransaction({
            ...data,
          })
        )
      )
    }
  }

  static _signTx = async (wallet: IWallet, data: (string | IDomainData)[]) => {
    if (!Array.isArray(data)) data = [data]
    if (NativeBridge.isBridge()) {
      const walletAddress = wallet.address
      return NativeBridge.Send('SIGN_MEASAGE', { data, walletAddress })
    } else {
      const provider = await WALLET._getWalletProvider()
      return Promise.all(
        data.map(async (data) => {
          if (typeof data === 'string') {
            return provider?.[0].signMessage(ethers.getBytes(data))
          } else {
            let signature = await provider?.[0].signTypedData(data.domainData, data.types, data.values)
            const r = signature.slice(0, 66)
            const s = '0x' + signature.slice(66, 130)
            const v = '0x' + signature.slice(130, 132)
            return { r, s, v }
          }
        })
      )
    }
  }
  static HandleWCRequest = async (wallet: IWallet, chainId: CHAIN_ID, payload: any) => {
    if (typeof payload === 'string') {
      return (await WALLET._signTx(wallet, [payload]))[0]
    } else {
      const data = [{ ...payload, chain: chainId }]
      return (await WALLET._handleGasTx(wallet, data))[0]
    }
  }
  static Send = async ({
    wallet,
    to,
    value,
    sourceChain,
    destinationChain,
    destinationToken,
    ENS,
    orderId,
    remark,
    order,
  }: {
    options?: { gasPrice?: string }
    wallet: IWallet
    to: string
    value: string
    sourceChain: CHAIN_ID
    destinationChain: number | CHAIN_ID.ARBITRUM
    destinationToken?: string
    ENS?: boolean
    orderId?: string
    remark: string
    order: any
  }) => {
    let orderHash: string = order && order.orderHash
    let allowancePayloads: IDomainData[] = []
    if (ENS) {
      const p = rpcProvider[CHAIN_ID.MAINNET]
      to = (await p.resolveName(to)) || ''
      if (to === '') {
        throw new Error("Can't resolve ENS")
      }
    }
    if (!(OrderType.MERCHANT && order?.orderHash)) {
      const { result } = await PaymentService.GetDataToSign({
        orderId,
        senderAddress: wallet.address,
        receiverAddress: to,
        sourceChain: sourceChain,
        destinationChain: destinationChain || sourceChain,
        destinationToken: destinationToken || tokenSymbolMap[`${destinationChain}-USDC`].tokenAddress,
        amount: value, // Handling the converstion on backend
        minAmountOut: parseUnits(parseFloat(value).toFixed(5), tokenSymbolMap[`${destinationChain}-USDC`].decimals).toString(),
        orderType: OrderType.P2P,
        remark,
      })
      if (!result) return
      allowancePayloads = result.allowancePayloads
      orderHash = result.orderHash
    }

    const [orderSig, ...allowanceSig] = await WALLET._signTx(wallet, [orderHash, ...allowancePayloads])
    allowanceSig.forEach((data: { r: string; s: string; v: string; chainId: CHAIN_ID; verifyingContract: string; walletAddress: string; value: number; deadline: string }, i: number) => {
      data.chainId = allowancePayloads[i].domainData.chainId
      data.verifyingContract = allowancePayloads[i].domainData.verifyingContract
      data.walletAddress = wallet.address
      data.value = allowancePayloads[i].values.value
      data.deadline = allowancePayloads[i].values.deadline
    })
    return PaymentService.ProcessOrder(orderHash, { signedOrder: orderSig, signedApprovalData: allowanceSig })
  }
  // static SetupRecovery = async (password: string) => {
  //     const { _id: userId } = queryClient.getQueryData(["user"]) as any
  //     const wallets = Object.values(WALLET.GetAll() || {}).filter((_: any) => _.userId === userId)
  //     const { result } = await WalletService.SetupRecovery(wallets.map((_: any) => _._id))
  //     let keys = result
  //         .map(({ _id, pass_phrase }: any) => {
  //             const wallet = WALLET.Get(_id)
  //             const privateKey = CRYPTO.DecryptData(wallet.key, pass_phrase)
  //             // const encryptedPK = CRYPTO.EncryptData(privateKey, password)
  //             return privateKey
  //         })
  //         .join("###")
  //     const encryptedKey = CRYPTO.EncryptData(keys, password)
  //     const secret = toUint8Array(encryptedKey.normalize("NFKC"))
  //     const [userPart, serverPart] = await split(secret, 2, 2)
  //     const userPartHash = await sha256(buf2hex(userPart))
  //     await WalletService.SendServerSecret({
  //         serverPart: buf2hex(serverPart),
  //         hash: userPartHash,
  //     })
  //     const encryptedUserPart = CRYPTO.EncryptData(buf2hex(userPart), password)
  //     createAndDownload(encryptedUserPart, "recovery_file.txt")

  //     // //recovery
  //     // const DecryptUserData = CRYPTO.DecryptData(encryptedUserPart, password)
  //     // const reconstructed = await combine([hexStringToArrayBuffer(DecryptUserData), hexStringToArrayBuffer(buf2hex(serverPart))])
  //     // console.log(CRYPTO.DecryptData(arrayBuffertoString(reconstructed), password))
  // }
  static RecoverAccount = async (encryptedUserPart: string, password: string) => {
    try {
      const DecryptUserData = CRYPTO.DecryptData(encryptedUserPart, password)
      const hash = await sha256(DecryptUserData)
      const {
        result: { serverPart },
      } = await WalletService.GetServerSecret(hash)
      const reconstructed = await combine([hexStringToArrayBuffer(DecryptUserData), hexStringToArrayBuffer(serverPart)])
      alert(CRYPTO.DecryptData(arrayBuffertoString(reconstructed), password))
    } catch (error) {
      console.log(error)
      alert('Failed to recover account, Please try again after some time.')
    }
  }
}
// function buf2hex(buffer: ArrayBuffer) {
//     // buffer is an ArrayBuffer
//     return [...new Uint8Array(buffer)].map((x) => x.toString(16).padStart(2, "0")).join("")
// }
