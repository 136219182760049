import {  ReadableStatus } from '@/interface'
import { useMemo } from 'react'
import { CheckCircle, CircleX, Loader } from 'lucide-react'

const TransactionStatusIcon = ({ status }: { status: string }) => {
  let icon = useMemo(() => {
    switch (status) {
      case ReadableStatus.PROCESSING:
      case ReadableStatus.INITIALIZED:
        return <Loader className=" animate-spin-slow " size={18} />
      case ReadableStatus.COMPLETED:
        return <CheckCircle color="#30b532" size={18} />
      case ReadableStatus.CANCELLED:
      case ReadableStatus.FAILED:
        return <CircleX color="#e30026" />
      default:
        return ''
    }
  }, [status])
  return icon
}
export default TransactionStatusIcon
