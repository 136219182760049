import { rewardService } from '@/api'
import { Button } from '@/cn/ui/button'
import { Input } from '@/cn/ui/input'
import { useToast } from '@/cn/ui/use-toast'
// import { useAppStore } from "@/store"

import { queryClient } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
const referralCode = sessionStorage.getItem('referral') || ''
const SubmitReferralCode = () => {
  const { toast } = useToast()
  const { register, handleSubmit } = useForm<{ referralCode: string }>({
    defaultValues: {
      referralCode,
    },
  })
  const mutation = useMutation((referralCode: string) => rewardService.SubmitReferralCode(referralCode), {
    onSuccess: async () => {
      toast({
        title: 'Referral Code Submitted successfully',
      })
      await queryClient.invalidateQueries(['user'])
    },
    onError: (error: any) => {
      toast({
        title: error.message,
        variant: 'destructive',
      })
    },
  })
  // const rejectMutation = useMutation(() => rewardService.SubmitReferralCode(), {
  //   onSuccess: async () => {
  //     await queryClient.invalidateQueries(['user'])
  //   },
  //   onError: (error: any) => {
  //     toast({
  //       title: error.message,
  //       variant: 'destructive',
  //     })
  //   },
  // })
  const handleSave = (data: { referralCode: string }) => {
    if (!data.referralCode) {
      return toast({
        title: 'Please Enter Referral code',
        variant: 'destructive',
      })
    }
    mutation.mutate(data.referralCode)
  }
  return (
    <div className="h-full bg-bakground text-foreground">
      <form onSubmit={handleSubmit(handleSave)} className=" py-4 flex justify-between flex-col gap-4 h-full  px-6">
        <div className="flex flex-grow flex-col gap-4 items-center justify-center ">
          <div className="flex flex-col gap-1">
            <h3>Enter Referral Code</h3>
          </div>
          <div className="grid w-full items-center gap-1.5">
            <div className="flex items-center gap-2 w-full">
              <Input {...register('referralCode')} placeholder="Referral Code" />
            </div>
          </div>
          <div className="w-full flex flex-col gap-3 mt-4">
            <Button loading={mutation.isLoading} variant="default" className="w-full">
              Continue
            </Button>
            <p className="text-gray-600">Oops! CrayAPP is invite-only 🤭. Hit up a friend for a referral code or DM us to snag access!</p>
            {/* <Button type="button" onClick={() => rejectMutation.mutate()} loading={rejectMutation.isLoading} variant="secondary" className="w-full">
              Skip
            </Button> */}
          </div>
        </div>
      </form>
    </div>
  )
}
export default SubmitReferralCode
